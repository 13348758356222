import { Component } from '@angular/core'

@Component({
  selector: 'app-detail-reports',
  templateUrl: './detail-reports.component.html',
  styleUrl: './detail-reports.component.scss'
})
export class DetailReportsComponent {

}
