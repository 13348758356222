import { AnalyticsService } from './analytics.service'
import { NgModule } from '@angular/core'
import { AnalyticsRoutingModule } from './analytics-routing.module'
import { AnalyticsComponent } from './analytics.component'
import { AppBaseModule } from '../../common/modules/app-base.module'
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component'
import { InstallDashboardComponent } from './install-dashboard/install-dashboard.component'
import { WirelessDashboardComponent } from './wireless-dashboard/wireless-dashboard.component'
import { DetailReportsComponent } from './detail-reports/detail-reports.component'
import { SalesOrderHistoryComponent } from './detail-reports/sales-order-history/sales-order-history.component'
import { PendingOrdersComponent } from './detail-reports/pending-orders/pending-orders.component'
import { WscComponent } from './detail-reports/wsc/wsc.component'
import { LeadsComponent } from './detail-reports/leads/leads.component'
import { ContactFollowUpsComponent } from './detail-reports/contact-follow-ups/contact-follow-ups.component'
import { ActivitiesComponent } from './detail-reports/activities/activities.component'


@NgModule({
  declarations: [AnalyticsComponent, SalesDashboardComponent, InstallDashboardComponent, WirelessDashboardComponent, DetailReportsComponent, SalesOrderHistoryComponent, PendingOrdersComponent, WscComponent, LeadsComponent, ContactFollowUpsComponent, ActivitiesComponent],
  imports: [
    AppBaseModule,
    AnalyticsRoutingModule
  ],
  providers: [
    AnalyticsService
  ],
})
export class AnalyticsModule { }
