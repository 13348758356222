import { Component } from '@angular/core'

@Component({
  selector: 'app-sales-order-history',
  templateUrl: './sales-order-history.component.html',
  styleUrl: './sales-order-history.component.scss'
})
export class SalesOrderHistoryComponent {

}
