import { Component, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { HomeService } from '../home/home.service'

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss'
})
export class AnalyticsComponent {

  isLoggedIn$: Observable<boolean>
  homeService: HomeService = inject(HomeService)

  constructor() {
    this.isLoggedIn$ = this.homeService.loggedIn$
  }
}
