import { Component } from '@angular/core'

@Component({
  selector: 'app-wsc',
  templateUrl: './wsc.component.html',
  styleUrl: './wsc.component.scss'
})
export class WscComponent {

}
