<div class="container-lg">
    <p-card header="Analytics" [style]="{'text-align': 'center'}"
        *ngIf="isLoggedIn$ | async; else loginMessageTemplate">
        <p-divider></p-divider>
        <p>Analytics will go here!!! </p>
    </p-card>
    <ng-template #loginMessageTemplate>
        <p-card header="Analytics" [style]="{'text-align': 'center'}">
            <p>Please <a routerLink="/home">Login</a> to access this page</p>
        </p-card>
    </ng-template>
</div>