// import { AnalyticsComponent } from './analytics.component'
import { SalesOrderHistoryComponent } from './detail-reports/sales-order-history/sales-order-history.component'
import { WscComponent } from './detail-reports/wsc/wsc.component'
import { PendingOrdersComponent } from './detail-reports/pending-orders/pending-orders.component'
import { LeadsComponent } from './detail-reports/leads/leads.component'
import { ContactFollowUpsComponent } from './detail-reports/contact-follow-ups/contact-follow-ups.component'
import { ActivitiesComponent } from './detail-reports/activities/activities.component'
import { InstallDashboardComponent } from './install-dashboard/install-dashboard.component'
import { WirelessDashboardComponent } from './wireless-dashboard/wireless-dashboard.component'
import { DetailReportsComponent } from './detail-reports/detail-reports.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component'

const routes: Routes = [
  // { path: '', component: AnalyticsComponent },
  { path: 'salesDashboard', component: SalesDashboardComponent },
  { path: 'installDashboard', component: InstallDashboardComponent },
  { path: 'wirelessDashboard', component: WirelessDashboardComponent },
  { path: 'detailReports', component: DetailReportsComponent },
  { path: 'detailReports/activities', component: ActivitiesComponent },
  { path: 'detailReports/contactFollowUps', component: ContactFollowUpsComponent },
  { path: 'detailReports/leads', component: LeadsComponent },
  { path: 'detailReports/pendingOrders', component: PendingOrdersComponent },
  { path: 'detailReports/wsc', component: WscComponent },
  { path: 'detailReports/salesOrderHistory', component: SalesOrderHistoryComponent }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnalyticsRoutingModule { }
