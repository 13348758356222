import { Component } from '@angular/core'

@Component({
  selector: 'app-install-dashboard',
  templateUrl: './install-dashboard.component.html',
  styleUrl: './install-dashboard.component.scss'
})
export class InstallDashboardComponent {

}
