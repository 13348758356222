import { Component } from '@angular/core'

@Component({
  selector: 'app-wireless-dashboard',
  templateUrl: './wireless-dashboard.component.html',
  styleUrl: './wireless-dashboard.component.scss'
})
export class WirelessDashboardComponent {

}
