import { Component } from '@angular/core'

@Component({
  selector: 'app-contact-follow-ups',
  templateUrl: './contact-follow-ups.component.html',
  styleUrl: './contact-follow-ups.component.scss'
})
export class ContactFollowUpsComponent {

}
